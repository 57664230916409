import InfoSlider from "components/common/InfoSlider";
import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function ThankYouPageRoot() {
  const renderContent = data => {
    const infoSlides = [
      {
        tabTitle: "Proactive Alerts",
        title:
          "Stay in the know with real-time alerts for faces, vehicles, and more.",
        description:
          "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
        image: data.infoSlide3,
      },
      {
        tabTitle: "Easy Investigations",
        title: "Save time with multi-camera investigations and smart search.",
        description:
          "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
        image: data.infoSlide4,
      },
      {
        tabTitle: "Seamless Deployment",
        title: "Register plug-and-play devices in as little as 5 minutes.",
        description:
          "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates. ",
        image: data.infoSlide2,
      },
      {
        tabTitle: "Infinite Scalability",
        title: "Enjoy an all-in-one solution that grows with your needs.",
        description:
          "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
        image: data.infoSlide1,
      },
    ];
    return (
      <OnDemandThankYouPage
        videoId="w2nih7135p"
        title="Enhancing Campus Safety with Rhombus and Omnilert"
        description="Rhombus and Omnilert have joined forces to transform emergency response times on school campuses. When every second matters for school safety, Rhombus and Omnilert are dedicated to swiftly identifying and addressing potential threats."
      >
        <InfoSlider
          data={infoSlides}
          title="The Benefits of Rhombus"
          color="var(--nuetral-100)"
          button
          fancy
        />
      </OnDemandThankYouPage>
    );
  };
  const query = graphql`
    query {
      infoSlide1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
